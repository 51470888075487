import marker from 'assets/images/home/mark.png'
import { ScrollingCarousel } from 'components/ReactCarousel'
import { EmptySkeletonWrapper } from 'components/Scroller/CardScroller'
import { Text } from 'components/Text'
import { siteSettings } from 'hooks'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { setVideoAutoplay } from 'redux/actions/site'
import { searchEventsAndMatches } from 'restful-client/games'
import {
    EventElement,
    EventOrMatch,
    MatchElement,
} from 'restful-client/types/games'
import { EventCardProps, GameCardProps } from 'types/components/GameCard'
import { isLive, thumbNailLink } from 'utils/common-helper'
import { hasActiveOrUpcomingGame, sortLiveStream } from 'utils/helper-date'
import ThumbnailCard from '../../../../components/Card/ThumbnailCard/ThumbnailCard'

const GameDayView: React.FC = (props: any) => {
    const { type, initialData, searchInput, sport } = props
    const dispatch = useDispatch()
    const ssrData = initialData?.initialData?.matches

    const [eventMatchData, setEventMatchData] = useState<EventOrMatch[]>(
        ssrData ?? [],
    )

    const fetchData = useCallback(
        async (searchInput: string | undefined, sport: string | undefined) => {
            //  Filter out old games
            const twoDaysAgo = new Date(
                Date.now() - 3_600_000 * 24 * 2,
            ).toISOString()
            const twoHoursAgo = new Date(
                Date.now() - 3_600_000 * 2,
            ).toISOString()

            const recent = (match: MatchElement | EventElement) => {
                if (
                    // If the match is older than 2 days, don't show it
                    match.start_datetime < twoDaysAgo ||
                    // If the match hasn't gone live and it's more than 2 hours old, don't show it
                    (match.stream_info?.status !== 'active' &&
                        match.start_datetime < twoHoursAgo)
                ) {
                    return false
                }
                return true
            }

            const data = await searchEventsAndMatches(searchInput, sport)

            if (data?.length) {
                setEventMatchData(
                    siteSettings('home_page.filter_recent_matches')
                        ? sortLiveStream(data.filter(recent))
                        : data,
                )
            }
        },
        [searchInput, sport],
    )

    useEffect(() => {
        fetchData(searchInput, sport)
        const interval = setInterval(() => {
            if (hasActiveOrUpcomingGame(eventMatchData)) {
                fetchData(searchInput, sport)
            }
        }, siteSettings<number>('home_page.polling_interval_ms'))
        return () => clearInterval(interval)
    }, [searchInput, sport])

    return (
        <>
            <Text
                fColor="white"
                fSize={1.5}
                fWeight={700}
                mode="h2"
                padding="0 0 10px 0"
            >
                {type}
            </Text>
            {_.isEmpty(eventMatchData) && (
                <EmptySkeletonWrapper>
                    <Text>No matches schedules.</Text>
                </EmptySkeletonWrapper>
            )}
            <ScrollingCarousel type="Live">
                {eventMatchData?.map((match: any, index: number) => {
                    const isEvent = !match?.home_team_id && !match?.away_team_id
                    if (isEvent) {
                        const backgroundImageSport =
                            match?.event_type === 'club'
                                ? match?.club?.sport?.background_image?.url
                                : match?.league?.sports?.background_image?.url
                        const item: EventCardProps = {
                            id: match.id,
                            event_name: match.name,
                            backgroundImage: thumbNailLink(
                                match.stream_info.video_asset_id,
                                500,
                                match.image,
                            ),
                            mode: type !== 'Replays' ? 'Event' : 'Replay',
                            event_type: match?.event_type,
                            isLive: isLive(
                                match.start_datetime,
                                match.stream_info.status,
                            ),
                            users: 0, //TODO: get the number of users watching
                            date: match.start_datetime,
                            is_event: true,
                            clubImage1: match?.club?.logo,
                            clubName1: match?.club?.display_name,
                            event_slug: match?.slug,
                            league_slug: match?.league?.slug,
                            clubName: match?.club?.name,
                            leagueName: match?.league?.name,
                            leagueImage: match?.league?.logo
                                ? match?.league?.logo
                                : marker,
                            club_slug: match?.club?.slug,
                            backgroundImageSport,
                            backgroundImageLeague:
                                match?.league?.background_image?.url,
                        }

                        return (
                            <div
                                key={`game-day-view-key${index}`}
                                onClick={() => dispatch(setVideoAutoplay(true))}
                            >
                                <ThumbnailCard {...item} {...props} />
                            </div>
                        )
                    } else {
                        const item: GameCardProps = {
                            id: match?.id,
                            backgroundImage: thumbNailLink(
                                match?.stream_info?.video_asset_id,
                                500,
                                match?.thumbnail_url,
                            ),
                            clubImage1: match?.home_team?.club?.logo,
                            clubName1: match?.home_team?.club?.display_name,
                            clubImage2: match?.away_team?.club?.logo,
                            clubName2: match?.away_team?.club?.display_name,
                            leagueImage: match?.league?.logo
                                ? match?.league?.logo
                                : marker,
                            leagueName: match?.league?.name,
                            roundName: match?.round_name,
                            matchName: match?.name,
                            mode: type !== 'Replays' ? 'Day' : 'Replay',
                            isLive: isLive(
                                match?.start_datetime,
                                match?.stream_info?.status,
                            ),
                            users: 0, //TODO: get the number of users watching
                            date: match?.start_datetime,
                            matchClubs: {
                                match_id: match?.id,
                                home_club_id: match?.home_team?.club?.id,
                                away_club_id: match?.away_team?.club?.id,
                                home_slug: match?.home_team?.club?.slug,
                                away_slug: match?.away_team?.club?.slug,
                                league_slug: match?.league?.slug,
                            },
                            customOpponent: match?.custom_opponent,
                            is_event: match?.is_event,
                            backgroundImageSport:
                                match?.sport?.background_image?.url,
                            backgroundImageLeague:
                                match?.league?.background_image?.url,
                        }

                        return (
                            <div
                                key={`game-day-view-key${index}`}
                                onClick={() => dispatch(setVideoAutoplay(true))}
                            >
                                <ThumbnailCard {...item} {...props} />
                            </div>
                        )
                    }
                })}
            </ScrollingCarousel>
        </>
    )
}

const mapStateToProps = (state: { site: { clubs: any } }) => ({
    siteClubs: state.site.clubs,
})

const mapDispatchToProps = {
    setVideoAutoplay: setVideoAutoplay,
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(GameDayView)
